import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ServiceCalendar, Loading } from "./components";
import { computeRideTime } from "./computeRideTime";

const BikeList = ({ bikes, ...rest }) => (
  <select {...rest}>
    <option disabled selected>
      {" -- select a bike -- "}
    </option>
    {bikes.map((bike, i) => (
      <option key={`${i}:${bike.name}`} value={bike.name}>
        {bike.name}
      </option>
    ))}
  </select>
);

const RideTime = styled.h1`
  color: white;
`;

const WaitMessage = styled.h2`
  color: white;
`;

const activityUrl = (lastServiceDate, page) =>
  `https://www.strava.com/api/v3/athlete/activities?after=${lastServiceDate}&page=${page}&per_page=150`;

const ServiceScheduler = (props) => {
  const [bikeList, setBikeList] = useState([]);
  const [selectedBike, setSelectedBike] = useState(null);
  const [lastServiceDate, setLastServiceDate] = useState(null);
  const [rideTime, setRideTime] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.accessToken) {
      //do fetch call for bikes & parts on bike
      fetch("https://www.strava.com/api/v3/athlete", {
        headers: { Authorization: `Bearer ${props.accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            throw new Error(data);
          }
          setBikeList(data.bikes.map(({ id, name }) => ({ id, name })));
        })
        .catch(console.error);
    }
  }, [props.accessToken]);

  useEffect(() => {
    (async function () {
      if (lastServiceDate && selectedBike) {
        let loading = true;
        setLoading(loading);
        let page = 1;
        let rideTime = 0;
        while (loading) {
          const activitySet = await (
            await fetch(activityUrl(lastServiceDate, page), {
              headers: {
                Authorization: `Bearer ${props.accessToken}`,
              },
            })
          ).json();

          rideTime = rideTime + computeRideTime(activitySet, selectedBike);

          if (activitySet.length < 1 || page > 10 || rideTime > 200) {
            loading = false;
            setLoading(loading);
          }
          page = page + 1;
        }
        setRideTime(rideTime);
      }
    })();
  }, [lastServiceDate, props.accessToken, selectedBike]);

  const handleBikeChange = (e) => {
    const selectedBike = bikeList.find((bike) => bike.name === e.target.value);
    setSelectedBike(selectedBike);
    setLastServiceDate(null);
  };

  const handleDateSelect = (e) => {
    setLastServiceDate(new Date(e.target.value).valueOf() / 1000);
  };

  return (
    <>
      {loading ? (
        <>
          <WaitMessage>Please Wait</WaitMessage>
          <Loading />
        </>
      ) : (
        <>
          <BikeList
            bikes={bikeList}
            onChange={handleBikeChange}
            defaultValue={selectedBike}
          />
          <br />
          <br />
          {selectedBike && !lastServiceDate ? (
            <>
              <ServiceCalendar
                selectedBike={selectedBike}
                onDateSelect={handleDateSelect}
              />
            </>
          ) : null}
          {selectedBike && lastServiceDate ? (
            <RideTime>
              You've had {Math.round(rideTime)} hours on this bike since then
            </RideTime>
          ) : null}
        </>
      )}
    </>
  );
};

export default ServiceScheduler;
